@import '../../../../../../styles/main.scss';

.sincronizar-ubicacion {
  &__container-parts {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;

    label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      cursor: pointer;
      padding: 10px;
      border-radius: 6px;
      border: 1px solid transparent;

      &.selected {
        border-color: $fontQuaternary;
      }

      input {
        cursor: pointer;
        accent-color: $primary;
      }

      p {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      
        .part-id {
          font-weight: 600 !important;
          color: $primary;
        }

        .part-name {
          color: $fontSecondary;
        }
      }

    }
  }
  button.btn {
    height: 37px !important;
  }
  
  button.btn-primary {
    width: 122px !important;
    padding: 0 !important;
  }
}
@import '../../../../styles/main.scss';

.dashboard-pantalla {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 0 32px 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @include custom(1600px) {
        margin-top: 5px;
    }
    &__total-pedidos {
        top: 25px;
        z-index: 100;
        position: absolute;
        font-size: 30px;
        color: $primary;

        @include custom(1600px) {
            font-size: 45px;
            top: 18px;
        }
    }
    &__current-week,
    &__records,
    &__status {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 18%;
        width: 100%;

        @include mdMax {
            flex-direction: column;
            height: 100%;
            gap: 10px;
            padding-bottom: 10px;
        }

        @include custom(1600px) {
            height: 17.5%;
        }
    }
    
    &__status {
        height: 25%;
        
        @include mdMax {
            height: 100%;
        }

        @include custom(1600px) {
            height: 24.5%;
        }
    }

    .dashboard-charts {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        height: 34%;

        @include mdMax {
            flex-direction: column;
            height: 100%;
            max-height: 800px;
            gap: 10px;
            padding-bottom: 16px;
        }

        h3 {
            color: $primary;
            font-weight: 400 !important;
            font-size: 30px !important;
            text-align: center;
            @include custom(1600px) {
                font-size: 45px !important;
            }
        }
        
        .chart-container {
            width: 100%;
            height: 100%;
            box-shadow: 4px 4px 10px $boxShadow;
            border: 1px solid rgb(248, 248, 248);
            border-radius: 10px;
            padding: 16px 16px 14px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            &__chart {
                height: 85%;
                width: 100%;
            }
        }
    }

    .each-square-results {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 16px;
        border-radius: 10px;
        box-shadow: 4px 4px 10px $boxShadow;
        border: 1px solid rgb(248, 248, 248);
        height: 100%;
        width: 31.5%;

        &.red-color {
            .each-square-results__results {
                color: $danger !important;
            }
        }

        &.green-color {
            .each-square-results__results {
                color: $green !important;
            }
        }

        @include mdMax {
            width: 100%;
        }

        &__title {
            font-size: 25px !important;
            color: $primary;
            font-weight: 400 !important;
            text-align: center;

            @include custom(1600px) {
                font-size: 35px !important;
            }
        }

        &__results {
            font-size: 45px !important;
            color: $fontSecondary !important;
            font-weight: 500 !important;
            color: $fontSecondary !important;
            line-height: 100% !important;

            @include custom(1600px) {
                font-size: 65px !important;
            }
        }

        &__extra{
            font-size: 12px !important;

            @include custom(1600px) {
                font-size: 17px !important;
            }
        }

        &.hoy {
            .each-square-results__results {
                color: $info !important;
            }
        }
    }

    &__status {
        .each-square-results {
            &__results {
                font-size: 90px !important;
                padding: 0px !important;
                line-height: 100% !important;

                @include custom(1600px) {
                    font-size: 125px !important;
                }
            }
        }
    }
}

//BREAKPOINTS:
$screen-sm-min: 576px;
$screen-sm-max: 575px;
$screen-md-min: 768px;
$screen-md-max: 767px;
$screen-lg-min: 992px;
$screen-lg-max: 991px;
$screen-xl-min: 1200px;
$screen-xl-max: 1199px;

@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}
 
@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}

@mixin custom($screen) {
    @media (min-width: $screen) {
        @content;
    }
}

@mixin smMax {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}

@mixin mdMax {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}

@mixin lgMax {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
}

@mixin xlMax {
    @media (max-width: #{$screen-xl-max}) {
        @content;
    }
}

@mixin customMax($screen) {
    @media (max-width: $screen) {
        @content;
    }
}
